<template>
  <div class="w-full flex flex-col" :class="$attrs.class">
    <div class="flex flex-col sm:flex-row w-full px-[6vw] gap-6">
      <!-- Left column with heading -->
      <div class="w-full sm:w-1/2 flex flex-col items-start">
        <h3 class="text-2xl sm:text-4xl font-semibold  text-center sm:text-left py-10 font-[600] tracking-tight text-designer font-nationalpark">Built with industry leaders every step of the way</h3>
      </div>
      
      <!-- Right column with marquee testimonials -->
      <div class="w-full relative flex gap-12 min-h-[400px]">
        <!-- Left testimonial column -->
        <Marquee vertical class="[--duration:15s] mask-fade h-[400px] w-full sm:w-auto" :pause-on-hover="true">
          <MarqueeReviewCard 
            v-for="testimonial in mobileTestimonials" 
            :key="testimonial.name"
            class="bg-white/70 dark:bg-black/70 text-background mb-4 squircle-20 !bg-gradient-to-r from-muted-foreground/10 to-muted-foreground/15 dark:from-background/5 dark:to-background/10"
            :name="testimonial.name"
            :username="testimonial.role"
            :body="testimonial.text"
            :img="testimonial.img"
          />
        </Marquee>

        <!-- Right testimonial column - hidden on mobile -->
        <Marquee vertical :reverse="true" class="[--duration:20s] mask-fade h-[400px] hidden sm:block" :pause-on-hover="true">
          <MarqueeReviewCard
            v-for="testimonial in rightTestimonials"
            :key="testimonial.name"
            class="bg-white/70 dark:bg-black/70 text-background mb-4 squircle-20 !bg-gradient-to-r from-muted-foreground/10 to-muted-foreground/15 dark:from-background/5 dark:to-background/10"
            :name="testimonial.name"
            :username="testimonial.role"
            :body="testimonial.text"
            :img="testimonial.img"
          />
        </Marquee>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const leftTestimonials = [
  {
    name: 'Leanne Gillies',
    role: 'Landscape Architect',
    text: 'This is new, this is different and it benefits the whole supply chain',
    img: 'general/avatars/leanne.png'
  },
  {
    name: 'Erik van Zuilekom',
    role: 'Designer',
    text: "This is great—I'm a visual person and everything is seamless",
    img: 'general/avatars/eric.jpeg'
  },
  {
    name: 'David Reid',
    role: 'Consultant',
    text: "I like what you're doing, you've spoken to the right people",
    img: 'general/avatars/david.jpg'
  }

]

const rightTestimonials = [
  {
    name: 'Mat Plummer',
    role: 'Project Manager',
    text: "I trust you guys and I support this because it can benefit the whole industry",
    img: 'general/avatars/mat.png'
  },
  {
    name: 'Azin Emampour',
    role: 'Landscape Designer',
    text: 'This is the future. You are the only ones who can do it',
    img: 'general/avatars/azin.jpg'
  },
  {
    name: 'Daniel Fuller',
    role: 'Consultant',
    text: "Every path you've gone down, I'm with you 100%—even just the maintenance is worth paying for especially for councils",
    img: 'general/avatars/DanielFuller.png'
  },
]

// Combine both lists for mobile view
const mobileTestimonials = [...leftTestimonials, ...rightTestimonials]
</script>

<style scoped>
.mask-fade {
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 5%,
    black 95%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 5%,
    black 95%,
    transparent 100%
  );
}
</style> 