<template>
  <!-- <div class="fixed top-0 left-0 w-full h-[80vh] from-muted-foreground/0 to-muted-foreground/20 bg-gradient-to-b z-[-1]"></div> -->
   <!-- <div class="fixed top-0 left-0 w-full h-[100vh] bg-white  z-[1] pointer-events-none bg-cover bg-center opacity-50 dark:opacity-100 mask-fade-bottom"> -->


   <!-- </div> -->
   <!-- mb-[400px] is for the footer height -->
   <!-- <div class="w-full flex flex-col justify-start items-start px-0 mx-0 bg-background z-90 relative mb-[400px]"> -->
     <!-- <div class="fixed top-0 left-0 h-screen w-screen-2xl bg-red-300 min-w-[100vw] z-90 relative"></div> -->
  
       
    
    <div ref="contentWrapperRef" class="flex flex-col items-start justify-start h-fit relative w-full z-[2] mx-auto pointer-events-none" :style="{ paddingBottom: `${footerHeight-100}px` }">
          <!-- Wrapper for interactive content -->
    <div class="pointer-events-auto w-full">
      <!-- <GodRays class="-mt-20  z-10 opacity-75 pointer-events-none mix-blend-multiply" />  -->

        <div class="!w-full select-none space-y-[3vh] w-full mb-12 bg-background md:px-[10vw] xl:px-[5vw] 2xl:px-[10vw] rounded-b-[3rem] pt-[20vh]" >
        
          <div ref="heading1" class="px-[6vw]"> 
            
          <p v-motion="headingMotions" class="text-5xl sm:text-[3.8rem] bg-clip-text text-transparent bg-designer bg-[length:200%_auto] animate-gradient tracking-[-0.03em] pt-3 font-nationalpark first-heading leading-[1.2em] font-[600]">The future of planting and procurement for landscape architects, contractors and growers</p>
          
          
          </div>
        
          <div class="w-full h-full flex flex-col text-4xl sm:[font-size:_clamp(0.8rem,4.5vw,45px)] items-center">
            <div v-motion="headingMotions" class="w-full h-full flex flex-col items-start justify-start second-heading z-[2]">

              <p class="px-[6vw] text-left leading-[1. 3em] -tracking-[0.025em] font-[400] text-xl sm:text-2xl  w-full max-w-[1300px] font-intervariable text-designer">
                Compliant, biodiverse, and profitable landscapes—We are building greenlife supply chain ahead of it's time.
              </p>
              <!-- book a demo button -->
              <div class="ml-[6vw] flex flex-row gap-3 justify-start font-nationalpark">
                <Button @click="scrollToDemo" class="mt-10 rounded-xl squircle-10">
                  {{ demoButtonText }}
                </Button>
                <Button @click="handleJoin" class="mt-10 rounded-xl squircle-10 group text-designer" variant="text">
                  {{ canCreateTeam ? 'start now' : 'join the waitlist' }}
                  <Icon name="solar:alt-arrow-right-linear" class="w-4 h-4 ml-1 mt-[2px] group-hover:ml-2 transition-all duration-300" />
                </Button>
              </div>
            </div>
            
            <div class="product-section w-full min-h-[40vh] flex flex-col items-center justify-start mt-[10vh]">
              <ContainerScroll :scrollY="scrollY">
                <template #title></template>
                <template #card>
                  <div class="relative w-full aspect-video overflow-hidden">
                    <NuxtImg
                      v-motion
                      :initial="{
                        x: 100,
                        opacity: 0
                      }"
                      :enter="{
                        x: 0,
                        opacity: 1,
                        transition: {
                          duration: 700
                        }
                      }"
                      preload
                      sizes="(max-width: 640px) 500px, 1200px"
                      width="1200"
                      height="675"
                      loading="eager"
                      :src="currentDemo"
                      class="h-full w-full object-cover object-left-top"
                      alt="SuperSeeded Screenshot"
                      format="webp"
                      quality="80"
                      :key="currentDemoIndex"
                      @mouseenter="handleDemoHover(true)"
                      @mouseleave="handleDemoHover(false)"
                    />
                  </div>
                </template>
              </ContainerScroll>


              <!-- Demo switcher buttons -->
              <div class="flex gap-2 mt-0 justify-center bg-muted/50 p-1.5 rounded-full">
                <button
                  v-for="(demo, index) in demos"
                  :key="demo.src"
                  @click="() => {
                    currentDemoIndex = index;
                    startDemoTimer();
                  }"
                  class="relative px-4 py-1.5 text-sm font-medium transition-all duration-300 rounded-full"
                  :class="[
                    currentDemoIndex === index 
                      ? 'text-background bg-primary shadow-sm' 
                      : 'text-muted-foreground hover:text-foreground/80',
                  ]"
                >
                  {{ demo.label }}
                </button>
              </div>
            </div>
            
            <div class="w-full flex flex-col items-center justify-center mt-[min(30vh,200px)] px-10 gap-10 md:gap-10">  
          <!-- add LogoCloud here  -->
          <p class="text-center text-4xl text-designer font-semibold tracking-tight mb-4 font-nationalpark mb-[5vw]">Deep integration with leading databases.<br/>Botanical, taxonomic, observational and market data.</p>
            <AnimatedLogoCloud :doubleRow="false" :logos="colorMode.value === 'dark' ? logos_dark : logos" />
            </div>
            

          <div class="w-screen pb-[500px] pt-[100px] relative">
            <div class="w-full flex flex-col md:flex-row items-start justify-between mt-[min(30vh,400px)] px-10 gap-[700px] md:gap-10">

              <!-- Left side with gradient background -->
              <div 
                ref="leftSection"
                class="w-full md:w-1/2 lg:w-2/3 md:pr-4 2xl:px-20 designer-demo" 
                
              >
                <div class="relative">
                  <ClientOnly>
                    <Vue3Lottie
                      ref="dragdrop"
                      :animationLink="colorMode.value == 'light' ? 'dragdrop_light2.json' : 'dragdrop_dark2.json'"
                      class="w-full max-w-[850px] relative z-[1] -top-[140px]"
                      :key="colorMode.value"
                    />
                  </ClientOnly>       

                  <div class="rounded-3xl flex items-end justify-end bg-[url('/images/backgrounds/gradient1.svg')] dark:bg-[url('/images/backgrounds/gradient1_dark.svg')] dark:hue-rotate-[20deg] bg-cover bg-center bg-no-repeat squircle-30 absolute top-0 left-0 w-full h-[535px] overflow-y-hidden" :style="{ height: `${demoHeight}px` }">
                    <div class="relative h-[40%] w-[90%] pt-20 pl-[30%] pr-[10%]">
                      <ClientOnly>
                        <MultiStepLoader class="scale-[1.2] origin-bottom" />
                      </ClientOnly>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Right side -->
              <div ref="demoSectionRef" class="sm:mt-0 w-full md:w-1/2 lg:w-1/3 rounded-4xl squircle-30 xl:px-10 2xl:px-15 schedule-section font-nationalpark">
                
                <div class="w-full h-fit bg-gradient-to-b from-muted/50 to-foreground/20 dark:from-foreground/10 dark:to-foreground/5  flex flex-col items-start justify-start p-5 pt-5 pb-10 md:p-8 rounded-3xl squircle-30">
                  <p class="text-2xl font-[600] tracking-tight">Book a demo</p>
                  
                  
                  <div class="flex flex-col gap-0 items-start my-3 [&:not(:first-child)]">                
                    <p class="text-sm font-[500] mt-0">Schedule a 30 min meeting with the founders. <a href="mailto:hi@superseeded.ai" class="text-sm underline font-[500] leading-5 cursor-pointer">hi@superseeded.ai</a>
                      
                    </p>
                    
                  </div>

                  <div class="flex w-full flex-row items-center justify-start mt-2">
                    <AnimatedTooltip :items="[
                      {id: 1, name: 'Edan Weis', designation: 'Co-Founder (edan@superseeded.ai)', image: '/general/avatars/edan.png'},
                      {id: 2, name: 'Shimon Regev', designation: 'Co-Founder (shimon@superseeded.ai)', image: '/general/avatars/shimon.jpeg'},
                      ]"/>
                    </div>
                    
                  <form @submit="onSubmit" class="w-full space-y-4 mt-5">
                    <FormField v-slot="{ componentField }" name="name">
                      <FormItem>
                        <FormControl>
                          <Input 
                            v-bind="componentField"
                            type="text" 
                            placeholder="Name" 
                            class="border-transparent"
                          />
                        </FormControl>
                        <FormMessage class="text-primary"  />
                      </FormItem>
                    </FormField>

                    <FormField v-slot="{ componentField }" name="email">
                      <FormItem>
                        <FormControl>
                          <Input 
                            v-bind="componentField"
                            type="email" 
                            placeholder="Work email" 
                            class="border-transparent"
                          />
                        </FormControl>
                        <FormMessage class="text-primary" />
                      </FormItem>
                    </FormField>

                    <FormField v-slot="{ componentField }" name="message">
                      <FormItem>
                        <FormControl>
                          <Textarea
                            v-bind="componentField"
                            placeholder="Add your message..."
                            class="border-transparent"
                            :rows="1"
                          />
                        </FormControl>
                        <FormMessage class="text-primary"  />
                      </FormItem>
                    </FormField>

                    <FormField v-slot="{ field }" name="date">
                      <FormItem class="flex flex-col">
                        <Popover v-model:open="isDatePickerOpen">
                          <PopoverTrigger as-child>
                            <FormControl>
                              <Button
                                id="date-picker-button"
                                variant="outline"
                                :class="[
                                  'w-full ps-3 text-start font-normal border-transparent',
                                  !dateChosen && 'text-muted-foreground'
                                ]"
                              >
                                <span>{{ dateChosen ? dayjs(dateChosen.toDate()).format('MMM D, YYYY') : "Pick a date (optional)" }}</span>
                                <Icon name="solar:calendar-line-duotone" class="ms-auto h-4 w-4 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent class="w-auto p-0" side="bottom" align="start">
                            <Calendar
                              v-model="dateChosen"
                              mode="single"
                              :selected-date="dateChosen"
                              calendar-label="Choose a date"
                              initial-focus
                              :min-date="dayjs().toDate()"
                              :max-date="dayjs().add(3, 'months').toDate()"
                              @update:model-value="handleDateChange"
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    </FormField>

                    <Button 
                      type="submit" 
                      class="w-full squircle-10"
                      :disabled="!form.meta.value.valid || isSubmitting"
                    >
                      <Loader2 v-if="isSubmitting" class="w-4 h-4 mr-2 animate-spin" />
                      {{ isSubmitting ? 'Booking...' : demoButtonText }}
                    </Button>
                    <p class="text-xs font-[300]">We respect your data. By submitting this form, you agree that we will contact you in
                      relation to our products and services, in accordance with our <a href="/legal/privacy" class="underline">Privacy policy</a>.</p>
                  </form>
                </div>
              </div>


            </div>
            
          </div>

            <div class=" designer-section mt-[min(10vh,200px)] transition-all duration-300" :style="{'transform': numberTickerValue >= numberTickerMax-0.5 ? 'translateX(0px)' : 'translateX(30px)'}">
              <ClientOnly>
                <NumberTicker 
                class="text-designer font-nationalpark min-w-[1.2em]"
                :value="numberTickerMax" 
                @update:value="numberTickerValue = $event"
                :scroll-control="true"
                :scroll-start="20"
                :scroll-end="80"
                :decimal-places="0"
                >
                <template #prepend>
                  <div class="">  
                    <p class="text-2xl sm:text-4xl w-full tracking-tight text-right pr-3  relative top-0 left-0 font-[600] font-nationalpark text-designer">Save</p>
                  </div>
                </template>
                  <template #append>
                    <div class="flex flex-row items-center justify-end">
                    <span v-motion-fade-visible class="text-[1em] relative transition-all duration-300 font-nationalpark text-designer" :style="{opacity: numberTickerValue >= numberTickerMax-0.5 ? 1 : 0, scale: numberTickerValue >= numberTickerMax-0.5 ? 1 : 0}">+</span>
                    <div class="flex flex-col items-center justify-end">
                      <p class="w-full text-2xl sm:text-4xl tracking-tight pl-4 sm:whitespace-nowrap leading-[1.3em] tracking-tight font-[600] font-nationalpark text-designer whitespace-nowrap transition-all duration-300" :style="{'transform': numberTickerValue >= numberTickerMax-0.5 ? 'translateX(0px)' : numberTickerValue < 10 ? 'translateX(-55px)' : 'translateX(-35px)'}">hours on planting design</p>
                    </div>
                    </div>
                  </template>
              </NumberTicker> 
              </ClientOnly> 
            </div>

            <div class="flex flex-row gap-4 mb-24 ">
              <div class="w-full h-fit py-10 flex items-center justify-center flex-col">
              <FallingStars  class="relative -mb-[163px] inset-0 w-[max(450px,40vw)] h-[300px] fallingstars pointer-events-none"
              :color="'bg-primary'"
              :speed="1"
              :thickness="0.6"
              :trail-length="14"
              :opacity="1"
              :count="100"
              :density="0"
               />
              <Button @click="createNew" class="designer-cta text-lg p-7 tracking-tight font-normal roudned-xl rounded-2xl squircle-15 bg-primary bg-primary pointer-events-auto z-10" :intensity="1">
                <Icon name="fa6-solid:file-pdf" class="mr-3 w-5 h-5"/> Review your plant schedule<Icon name="solar:alt-arrow-right-linear" class="w-6 h-6 ml-2 mt-[2px]" />
              </Button>
              </div>
            </div>

            <SlideCard
              image-src="general/snippets/01.png"
              image-position="left"
            >
              <template #text>
                <span> <Icon name="mynaui:flag-one-solid" class="w-8 h-8 -top-1 mr-2 relative inline-block text-destructive" /> Tour your plant list—visit every flower, soil or tree in your area</span>
              </template>
            </SlideCard>
            <SlideCard
              image-src="general/snippets/03.png"
              text="Easily compare trade-offs between mature/tubestock, cost, mortality and maintenance of your entire plant list"
              image-position="right"
            />
            <SlideCard
              image-src="general/snippets/09.png"
              text="Identify major hosts of exotic plant pests to prevent and mitigate risk of biosecruity threats"
              image-position="left"
            />
            <SlideCard
              image-src="general/snippets/04.png"
              text="Privately chat with your project data to guide accurate conversations, using LLM of your choice"
              image-position="right"
            />
            <SlideCard
              image-src="general/snippets/07.png"
              text="Powerful visual analyses of nursery stock and pricing—via EvergreenConnect"
              image-position="left"
            />
            

            <div class="relative mt-[10vh] testimonials">
              <Testimonials class="" />
            </div>
            
            <ClientOnly>
              <Vue3Lottie
              ref="dragdrop"
              :animationLink="colorMode.value == 'light' ? 'wellspring_light.json' : 'wellspring_light.json'"
              class="w-full max-w-[550px] sm:-left-[100px] relative z-[1] top-[100px]"
              :key="colorMode.value"
            />
            </ClientOnly>       

            <div class="w-[80vw] max-w-[850px] flex flex-col sm:items-end justify-end bg-gradient-to-b from-[#74d7d724] to-[#f28199]/15 squircle-30 sm:pt-10 rounded-3xl squircle-30 relative -top-[900px] mt-[600px] h-[70vh] max-h-[600px] sm:h-[80vh] sm:max-h-[400px] ">
            
              <div class="w-full sm:w-1/2 scale-[0.75] sm:scale-[1] sm:scale-[0.8] flex flex-col h-full">
                
                <div class="flex flex-col h-full justify-end sm:justify-start sm:block">
                  <div class="pb-5 w-full text-center sm:text-right text-2xl sm:text-4xl font-semibold text-[#F28199] sm:pr-20 mb-4 sm:mb-10 font-nationalpark">
                    Get paid for your expert knowledge
                  </div>

                  <div class="w-full sm:max-w-[90%] px-6 sm:pl-10">
                    <p class="text-xl sm:text-lg leading-[1.3em] -tracking-[0.025em] font-[350] text-designer !text-[#F28199] text-center sm:text-right">
                      Join the global marketplace where nature professionals pay for value-proven expertise—earn directly while digital intermediaries protect your reputation.
                      <Button class="self-center  text-lg p-7 mt-8  w-full sm:w-fit tracking-tight font-normal rounded-2xl squircle-15 bg-[#F28199] hover:bg-primary/80" :intensity="1" @click="user ? navigateTo('/wellspring') : navigateTo('/login')">
                        Go to Marketplace<Icon name="solar:alt-arrow-right-linear" class="w-6 h-6 ml-2 mt-[2px]" />
                      </Button>
                    </p>
                  </div>
                </div>
              </div>
              
            </div>

            
            <div class="relative -top-[800px] -mb-[800px] contractor-cta">
            
            <div v-if="false" class="w-full flex flex-col items-center justify-center px-20">
            <GlowBorder :border-radius="20" :border-width="windowWidth * 0.005" :duration="10"
              :color="['#A07CFE', '#FE8FB5', '#FFBE7B']" class="mt-[10vh] shadow-xxl mb-[calc(30px+5vw)] max-w-[1000px]"
              v-motion-fade-visible
            >
              <ClientOnly>
                <VideoPlayer src="https://ik.imagekit.io/8qxqs9cznn/wellspring_demo2.mp4" />
              </ClientOnly>
            </GlowBorder>
            </div>
            

            <div v-if="false" class="w-full flex flex-col sm:flex-row gap-6 sm:gap-12 max-w-[60vw] sm:max-w-[900px] h-fit justify-evenly max-w-[800px] relative z-[3] isolate">
            
              <div class="squircle-40 sm:squircle-80w-full min-w-12 min-h-[300px] flex flex-col gap-2 sm:gap-4 justify-end p-4 sm:p-8 relative overflow-hidden"
                style="background-image: url('/hort_consultant.png'); background-size: cover; background-position: center;">
                <div class="absolute inset-0 bg-gradient-to-t from-muted via-muted/40 to-transparent mix-blend-hard-light"></div>
                <div class="relative z-10 rounded-2xl p-4 pb-0 w-full">
                  <p class="text-[0.55em] leading-[1.5em] sm:text-2xl font-[400] w-full tracking-tight w-full items-center text-center">
                    <Icon name="streamline:nature-ecology-rice-field-sun-rise-set-field-crop-produce-farm" class="w-7 h-7 -mt-2 w-full" />Wellspring
                  </p>
                  <p class="text-[0.55em] leading-[1.5em] sm:text-lg text-center font-[300] w-full tracking-tight">Consultants earn by sharing best practice</p>
                </div>
              </div>
            <div class="squircle-40 sm:squircle-80w-full min-w-12 min-h-[300px] flex flex-col gap-2 sm:gap-4 justify-end p-4 sm:p-8 relative overflow-hidden"
              style="background-image: url('/greenline_bg.png'); background-size: cover; background-position: center;">
                <div class="absolute inset-0 bg-gradient-to-t from-muted via-muted/80 to-transparent"></div>
                <div class="relative z-10">
                  <p class="text-[0.55em] leading-[1.5em] sm:text-2xl text-center font-[400] w-full tracking-tight">
                    <Icon name="material-symbols:show-chart-rounded" class="w-7 h-7 -mt-2 w-full" /> Sightline
                  </p>
                  <p class="text-[0.55em] leading-[1.5em] sm:text-lg text-center font-[300] w-full tracking-tight">Suppliers gain better visibility into demand</p>
                </div>
              </div>
            
            <div class="squircle-40 sm:squircle-80w-full min-w-12 min-h-[300px] flex flex-col gap-2 sm:gap-4 justify-end p-4 sm:p-8 relative overflow-hidden"
              style="background-image: url('/design_bg.png'); background-size: cover; background-position: center;">
                <div class="absolute inset-0 bg-gradient-to-t from-muted via-muted/80 to-transparent"></div>
                <div class="relative z-10">
                  <p class="text-[0.55em] leading-[1.5em] sm:text-2xl text-center font-[400] w-full tracking-tight">
                    <Icon name="ant-design:aliwangwang-outlined" class="w-7 h-7 -mt-2 w-full" /> Design
                  </p>
                  <p class="text-[0.55em] leading-[1.5em] sm:text-lg text-center font-[300] w-full tracking-tight">Faster schedules with a grower-based pallets</p>
                </div>
              </div>

              
              
              
            </div>       

            
            
            
            
            


              


              <div v-if="false" class="flex flex-cols gap-4">
                <div class="w-fit h-fit ">
              <div class="rounded-full w-10 h-10 flex items-center justify-center bg-primary text-background font-apfel text-3xl">1</div>
              </div>
              <div class="w-fit align-center justify-center self-center items-center ">
                <div class="text-start self-center text-2xl md:text-4xl pb-24 tracking-tight font-regular w-full">
                
                <div class="mr-2 inline bg-background/60 rounded-2xl p-2">
                <Icon name="streamline:nature-ecology-rice-field-sun-rise-set-field-crop-produce-farm" class="w-7 h-7 -mt-2" /><span class="ml-2 font-semibold tracking-tight">Wellspring</span>
                </div>
                <div class="pt-2 text-[0.8em] mb-[250px]">
                The marketplace of best practice for nature professions
              </div>
              
              <PopCard />
            </div>
          </div>
        </div>


              
              
                <div v-if="false" v-motion-fade-visible  class=" relative flex h-[500px] w-full max-w-[1200px] bg-background/50 squircle-20 p-6 overflow-hidden animated-list" ref="animatedListContainer">
                  <div class="flex w-full">
                    <div class="w-1/2 scale-[0.75] sm:scale-[1] pl-10 pt-10 sm:scale-[0.9]">
                      <ClientOnly>
                      <AnimatedList :delay="1000">
                        <template #default>
                          <Notification
                          class="rounded-2xl"
                            v-for="(item, idx) in notifications"
                            :key="idx"
                            :item="item.item"
                            :icon="item.icon"
                            :color="item.color"
                            :time="item.time"
                          />
                        </template>
                      </AnimatedList>
                    </ClientOnly>
                    </div>
                    <div class="w-1/2 pl-4 flex flex-col items-start justify-start ">
                      <p class="text-left text-2xl md:text-3xl  pt-14 px-10 pl-14 md:pl-10 md:py-10 pb-[10vh] tracking-tight font-regular w-full]"  v-motion :initial="{ opacity: 0, y: 100 }" :visible="{ transition: { opacity: { type: 'keyframes', ease: [0.45, 0, 0.55, 1], duration: 1600 }, y: { type: 'keyframes', ease: [0.22, 1, 0.36, 1], duration: 1600 }}, opacity: 1, y: 0}" :delay="200">
                        Automated review of your plant schedules, save time and reduce risk
                
        </p>
                    </div>
                  </div>
                </div>
              

                <div class="my-40 max-w-[600px] mx-auto px-10 text-designer">
                  <h2 class="text-4xl font-semibold tracking-tight text-designer mb-4">Frequently asked questions</h2>
                  <p class="leading-8 text-2xl text-muted-foreground mb-8 !text-designer">
                    <span @click="scrollToDemo" class="text-underline cursor-pointer underline text-designer">Schedule a meeting</span> if you can't find what you're looking for
                  </p>

                  <!-- Category Tabs -->
                  <div class="flex gap-6 mb-8 justify-center p-1.5 rounded-full w-full">
                    <button
                      v-for="(category, name) in ['general', 'pricing', 'features']"
                      :key="name"
                      @click="activeCategory = category"
                      class="relative px-4 py-1.5 text-sm font-medium transition-all duration-300 rounded-full border border-designer hover:bg-designer"
                      :class="[
                        activeCategory === category 
                          ? 'text-background bg-designer shadow-sm' 
                          : 'text-designer hover:text-background'
                      ]"
                    >
                      {{ category.charAt(0).toUpperCase() + category.slice(1) }}
                    </button>
                  </div>

                  <Accordion type="single" collapsible class="w-full">
                    <AccordionItem 
                      v-for="(faq, index) in filteredFaqs" 
                      :key="index" 
                      :value="`item-${index}`"
                      class="group overflow-hidden"
                    >
                      <AccordionTrigger class="flex gap-4 text-lg font-medium tracking-tight text-left justify-start bg-designer/10 m-3 p-3 rounded-2xl squircle-10 w-full ">
                        <ClientOnly>
                          <Icon :name="faq.icon" class="w-6 h-6 text-muted-foreground flex-shrink-0 !text-designer" />
                        </ClientOnly>
                        <span class="text-left flex-1 !text-designer">{{ faq.question }}</span>
                        <ClientOnly>
                          <Icon 
                            name="quill:chevron-down" 
                            class="w-4 h-4 flex-shrink-0 transition-transform duration-200 group-data-[state=open]:rotate-180 text-designer" 
                          />
                        </ClientOnly>
                      </AccordionTrigger>
                      <AccordionContent class="leading-7 text-lg text-muted-foreground px-6 w-full text-designer">
                        <div v-html="faq.answer" class="text-designer"></div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
                
              
          </div>
        </div>

        </div>
      
      </div>
    
    </div>
    
  
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { cn } from "~/lib/utils";
import avatar from 'gradient-avatar'
import { FileUpload, JumpBackIntoIt, GetNotified } from '#components';
import { RainbowButton } from '#components'
import { useProjectData } from '@/composables/projectData'
import { dummyProjectTitle } from '@/lib/utils'
import TeamSelectionDialog from '@/components/TeamSelectionDialog.vue'
import { slowOut } from '@/lib/utils'
import { AnimatedLogoCloud } from '@/components/Logocloud'
import { onMounted, onUnmounted } from 'vue'

const props = defineProps<{
  scrollY: number
}>()

const stickyText = ref(null)
const colorMode = useColorMode()

const isDark = computed(() => colorMode.value == 'dark')

import { Vue3Lottie } from 'vue3-lottie'
const client = useSupabaseClient()

const userStore = useUserStore()
const uiStore = useUiStore()
const { windowWidth } = storeToRefs(uiStore)
const projectStore = useProjectStore()
const isContextOpen = ref(false)
const showAlert = ref(false)

const motions = useMotions()

const heading1 = ref(null)

const dragdrop = ref<any>(null)

const numberTickerValue = ref(0)
const numberTickerMax = ref(40)

const demoSectionRef = ref<HTMLElement | null>(null)

const logos = [
  {
    name: "iNaturalist",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/01.svg",
  },
  {
    name: "BIEN",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/02.png",
  },
  {
    name: "WIKISPECIES",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/03.svg",
  },
  {
    name: "GBIF",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/04.svg",
  },
  {
    name: "ALA",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/05.png",
  },
  {
    name: "EPPO",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/06.png",
  },
  {
    name: "PADIL",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/07.svg?updatedAt=1739839834156",
  },
  {
    name: "EvergreenConnect",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/08.svg",
  },
  {
    name: "OpenVerse",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/09.svg",
  },
  {
    name: "OpenAustralia Foundation",
    path: "https://ik.imagekit.io/8qxqs9cznn/general/logos/data%20providers/10.svg",
  },
];

const logos_dark = computed(() => {
  return logos.map(logo => ({
    ...logo,
    path: logo.path.replace('data%20providers/', 'data%20providers/grayscale/')
  }))
})

const { $gsap } = useNuxtApp()  

const loading = ref(false)
const { plan, selectedTeam } = storeToRefs(userStore)
const { toggleBottomSheet, setBottomSheetDynamicComponent, setBottomDrawerConfig } = uiStore
const user = useSupabaseUser()

const { canCreateTeam } = useAccessControl()




const handleJoin = async () => {
  // If user is not logged in, redirect to register
  if (!user.value) {
    return navigateTo('/register')
  }

  // If user has access, go to dashboard
  if (canCreateTeam.value) {
    return navigateTo('/dashboard')
  }

  // Otherwise show waitlist bottom drawer
  setBottomDrawerConfig({
    title: '',
    component: markRaw(GetNotified),
    props: {
      email: user.value.email || ''
    }
  })
}

const headingMotions = {
  initial: {
    opacity: 0,
    y: 100
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1500,
      delay: 500,
      ease: [0.22, 1, 0.36, 1]
    }
  },
  leave: {
    opacity: 0,
    y: 100,
    transition: {
      duration: 1500,
      ease: [0.22, 1, 0.36, 1]
    }
  }
}

// Add these reactive refs for section visibility
const sectionVisibility = reactive({
  designerDemo: false,
  scheduleSection: false,
  designerSection: false,
  testimonials: false,
  designerCta: false,
  fallingstars: false
})

const initializeAnimations = () => {
  // Start the demo timer and preload images
  startDemoTimer();
  preloadComponents('NuxtImg');

  // Create refs for each section
  const designerDemoRef = ref<HTMLElement | null>(null)
  const scheduleSectionRef = ref<HTMLElement | null>(null)
  const designerSectionRef = ref<HTMLElement | null>(null)
  const testimonialsRef = ref<HTMLElement | null>(null)
  const designerCtaRef = ref<HTMLElement | null>(null)

  // Use intersection observer with refs
  const { stop: stopDesignerDemo } = useIntersectionObserver(
    designerDemoRef,
    ([{ isIntersecting }]) => {
      sectionVisibility.designerDemo = isIntersecting
    },
    { threshold: 0.5 }
  );

  const { stop: stopScheduleSection } = useIntersectionObserver(
    scheduleSectionRef,
    ([{ isIntersecting }]) => {
      sectionVisibility.scheduleSection = isIntersecting
    },
    { threshold: 0.5 }
  );

  const { stop: stopDesignerSection } = useIntersectionObserver(
    designerSectionRef,
    ([{ isIntersecting }]) => {
      sectionVisibility.designerSection = isIntersecting
    },
    { threshold: 0.5 }
  );

  const { stop: stopTestimonials } = useIntersectionObserver(
    testimonialsRef,
    ([{ isIntersecting }]) => {
      sectionVisibility.testimonials = isIntersecting
    },
    { threshold: 0.5 }
  );

  const { stop: stopDesignerCta } = useIntersectionObserver(
    designerCtaRef,
    ([{ isIntersecting }]) => {
      sectionVisibility.designerCta = isIntersecting
      sectionVisibility.fallingstars = isIntersecting
    },
    { threshold: 0.5 }
  );

  onUnmounted(() => {
    stopDesignerDemo();
    stopScheduleSection();
    stopDesignerSection();
    stopTestimonials();
    stopDesignerCta();
  });

  // Preload all demo images
  demos.forEach(demo => {
    const img = new Image();
    img.src = demo.src;
  });
};

const startDemoTimer = () => {
  if (demoTimer) clearInterval(demoTimer);
  demoTimer = setInterval(() => {
    if (!isPaused.value) {
      currentDemoIndex.value = (currentDemoIndex.value + 1) % demos.length;
    }
  }, 4000);
};

const handleDemoHover = (hovering: boolean) => {
  isPaused.value = hovering;
};

// Move cleanup into component lifecycle
onMounted(() => {
  initializeAnimations();
});

onActivated(() => {
  initializeAnimations();
});

onUnmounted(() => {
  // Clean up timer
  if (demoTimer) clearInterval(demoTimer);
});

const wellspringNotifications = [
  {
    item: {
      title: "Substitution accepted",
      message: "Isotoma axillaris",
    },
    time: "15m ago",
    icon: "solar:dollar-linear",
    // icon: "💲",
    color: "#F28199",
  },
  {
    item: {
      title: "Alternative viewed",
      message: "Suggested species",
    },
    time: "15m ago",
    icon: "solar:dollar-linear",
    // icon: "💲",
    color: "#F28199",
  },
  
]

const notifications = [
  {
    item: {
      title: "Substitution accepted",
      message: "Isotoma axillaris",
    },
    time: "15m ago",
    icon: "🌸",
    color: "#00C9A7",
  },
  {
    item: {
      title: "Biosecurity alert",
      message: "Xylella fastidiosa",
    },
    time: "10m ago",
    icon: "☣️",
    color: "#FFB800",
  },
  {
    item: {
      title: "Non-compliance",
      message: "Arum Lily (Zantedeschia aethiopica)",
    },
    time: "5m ago",
    icon: "💬",
    color: "#FF3D71",
  },
  {
    item: {
      title: "Contract novation",
      message: "New project owner assigned",
    },
    time: "2m ago",
    icon: "🗞️",
    color: "#1E86FF",
  },
];

import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-vue-next'

import { format } from 'date-fns'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Calendar } from '@/components/ui/calendar'

import type { DateValue } from '@internationalized/date'
import { parseDate } from '@internationalized/date'

const datePlaceholder = ref('Choose a date')
const dateChosen = ref<DateValue | undefined>(undefined)
const isDatePickerOpen = ref(false)

const formSchema = toTypedSchema(z.object({
  name: z.string({
    required_error: "Please enter your name",
  }),
  email: z.string({
    required_error: "Please enter your email",
  }).email({
    message: "Please enter a valid email address.",
  }).optional().or(z.literal('')).transform(val => {
    if (!val && user.value?.email) {
      return user.value.email
    }
    return val
  }),
  message: z.string().optional(),
  date: z.any().optional().transform(val => {
    if (!val) return null
    // Safely handle date conversion
    try {
      return dayjs(val.toDate()).toISOString()
    } catch (e) {
      return null
    }
  })
}))

const form = useForm({
  validationSchema: formSchema,
  initialValues: {
    name: '',
    email: user.value?.email || '',
    message: '',
    date: null
  }
})

const formMeta = computed(() => form.meta)


const isSubmitting = ref(false)

const demoButtonText = ref('Book a demo')

const onSubmit = form.handleSubmit(async (values) => {
  if (isSubmitting.value) return
  
  isSubmitting.value = true
  try {
    const { data, error } = await useFetch('/api/demo-booking', {
      method: 'POST',
      body: {
        user_id: user.value?.id,
        name: values.name,
        email: values.email,
        message: values.message,
        date: values.date
      }
    })
    console.log(data.value)
    if (data.value) {
      // get formatted date
      const formattedDate = dayjs(values.date).format('MMM D')
      demoButtonText.value = `Demo booked for ${formattedDate}`
    }

    if (error.value) {
      throw new Error(error.value?.message || 'Failed to book demo')
    }


    
    push.success({
      title: 'Success!',
      message: 'Your demo has been booked. We will contact you shortly.',
      type: 'success'
    })
    
    // Reset form and date picker state
    form.resetForm()
    dateChosen.value = undefined
    isDatePickerOpen.value = false
  } catch (error: any) {
    console.error('Error submitting form:', error)
    push.error({
      title: 'Error',
      message: error?.message || 'There was an error booking your demo. Please try again.',
      type: 'error'
    })
  } finally {
    isSubmitting.value = false
  }
})

const leftSection = ref<HTMLElement | null>(null)
const { height: demoHeight } = useElementSize(demoSectionRef)

const nameInputRef = ref(null)

import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

const scrollToDemo = () => {
  const { $gsap } = useNuxtApp()
  
  // Register ScrollToPlugin
  $gsap.registerPlugin(ScrollToPlugin)
  
  $gsap.to(document.querySelector('.scroll-container'), {
    duration: 1,
    scrollTo: {
      y: demoSectionRef.value,
      autoKill: true, // Prevents horizontal scrolling
      offsetY: 100
    },
    ease: 'power2.inOut',
    onComplete: () => {
      // Focus the name input after scroll completes
      const nameInput = document.querySelector('input[placeholder="Name"]')
      nameInput?.focus()
    }
  })
}

// Demo images data 
const demos = [
  { 
    src: 'general/screens/014_cropped.png',
    label: 'Workspace'
  },
  { 
    src: 'general/screens/maintenance.png',
    label: 'Maintenance'
  },
  { 
    src: 'general/screens/SCR-20241205-izxc.png',
    label: 'Discovery'
  }
];

const currentDemoIndex = ref(0);
const currentDemo = computed(() => demos[currentDemoIndex.value].src);

// Add timer control
let demoTimer: NodeJS.Timeout | null = null;
const isPaused = ref(false);

const activeCategory = ref('general')

// Preload FAQ icons
onNuxtReady(async () => {
  await prefetchComponents(['Icon'])
})

const faqs = {
  general: [
    {
      icon: 'solar:smile-circle-linear',
      question: 'Is there a free trial available?',
      answer: `Instead of a time-limited trial, we offer free access to our platform with limited usage. This allows you to explore our features and experience the value of our solution at your own pace. When you're ready to scale up, you can upgrade to unlock full platform capabilities`
    },
    {
      icon: 'solar:chat-square-like-linear',
      question: 'How does support work?',
      answer: 'Our support team is available during business hours (GMT+10; Melbourne Australia) to help you with any questions or issues you may have. You can reach us at <a class="underline" href="mailto:hi@superseeded.ai">hi@superseeded.ai</a>.'
    }
  ],
  pricing: [
    {
      icon: 'solar:card-linear',
      question: 'How much does it cost?',
      answer: `We're currently working closely with early adopters to develop pricing that reflects the true value of our platform. If you're interested in joining our early access program, sign up and you will be added to our waitlist. Reach out to us at <a class="underline" href="mailto:hi@superseeded.ai">hi@superseeded.ai</a> to discuss how we can work together.`
    }

  ],
  features: [
    {
      icon: 'solar:play-circle-linear',
      question: 'Do you integrate with other tools?',
      answer: `We're actively developing integrations with BIM systems and planting databases. While these integrations are part of our roadmap, we'd love to hear about the specific tools you use to help prioritize our development. Contact us at <a class="underline" href="mailto:hi@superseeded.ai">hi@superseeded.ai</a> to discuss your integration needs.`
    },
    {
      icon: 'solar:code-linear',
      question: 'Do you offer an API?',
      answer: `Yes, our platform will support API integration. While we're currently finalizing our API documentation, we can work with you to enable programmatic access to our services for your specific use case. Contact us at <a class="underline" href="mailto:hi@superseeded.ai">hi@superseeded.ai</a> to discuss your integration needs.`
    }
  ],
}

const filteredFaqs = computed(() => {
  return faqs[activeCategory.value] || []
})

const handleDateChange = (date: DateValue | undefined) => {
  dateChosen.value = date
  if (date) {
    form.setFieldValue('date', date)
  } else {
    form.setFieldValue('date', null)
  }
  isDatePickerOpen.value = false
}

import { inject } from 'vue'

// Get the footer height from the parent component
const footerHeight = inject('footerHeight', ref(0))
const footerRef = inject('footerRef', ref(null))
const contentWrapperRef = ref(null)

const { createNew } = useProjectData()
const seoDescription = ref('The future of planting design and procurement for landscape architects, contractors and growers.')

// Define base URL for absolute URLs
const baseUrl = 'https://superseeded.ai'
const ogImageUrl = 'https://ik.imagekit.io/8qxqs9cznn/general/screens/014_cropped.png'

// SEO Configuration
useHead({
  title: 'SuperSeeded.ai - The future of planting and procurement for landscape architects, contractors and growers.',
  templateParams: {
    separator: '·'
  },
  htmlAttrs: {
    lang: 'en'
  },
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon.png'
    }
  ]
})

// Add OG Image generation
defineOgImage({
  component: 'OgImage',
  props: {
    title: 'The future of planting and procurement for landscape architects, contractors and growers.',
    description: seoDescription.value,
    colorMode: colorMode.value,
    theme: '#18181B'
  },
  width: 1200,
  height: 630
})

useSeoMeta({
  title: 'SuperSeeded.ai - The future of planting and procurement for landscape architects, contractors and growers.',
  description: seoDescription.value,
  
  // Open Graph
  ogTitle: 'SuperSeeded.ai - The future of planting and procurement for landscape architects, contractors and growers.',
  ogDescription: seoDescription.value,
  ogImage: {
    url: ogImageUrl,
    width: 1200,
    height: 630,
    type: 'image/png',
  },
  ogUrl: baseUrl,
  ogType: 'website',
  ogSiteName: 'SuperSeeded.ai'
})

// Add Schema.org structured data
useSchemaOrg([
  defineWebPage({
    name: 'superseeded.ai—The future of planting design and procurement',
    description: seoDescription.value,
    image: '/images/screenshots/014_cropped.png',
    datePublished: '2025-02-20',
    dateModified: '2025-02-20'
  }),
  defineSoftwareApp({
    '@type': ['SoftwareApplication', 'WebApplication'],
    name: 'superseeded.ai Platform',
    description: 'Real-time availability, pricing and best practice advice for planting design and procurement.',
    operatingSystem: 'Web',
    applicationCategory: 'BusinessApplication',
    featureList: [
      'Real-time plant availability tracking',
      'Automated compliance checking',
      'Biosecurity risk assessment',
      'Integrated procurement workflow'
    ],
    screenshot: '/images/screenshots/014_cropped.png',
    image: '/images/screenshots/014_cropped.png',
    softwareVersion: '1.0.0'
  })
])

</script>

<style scoped>
.masked {
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.grad-bg{
background-color: #ffffff;
  background-image: radial-gradient(circle at 0% 17%, #4b1fff23 22%, transparent 38.05%), radial-gradient(circle at 44% -38%, #ff8ab38a 39%, transparent 52.05%), radial-gradient(circle at -33% 17%, #4dbbff57 34%, transparent 63.05%), radial-gradient(circle at -16% 17%, #0055fff5 11%, transparent 24.05%), radial-gradient(circle at 39% -49%, #fe20eb6f 35%, transparent 40.05%);
}

.logo-primary {
  filter: brightness(0) saturate(100%) invert(1) sepia(0) saturate(0) hue-rotate(0deg) !important;
  color: hsl(var(--primary));
}

.violet{
  font-family: 'violet-sans-regular' !important;
  font-weight: 600;
}

.mask-fade-bottom {
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
}

.initial-hidden {
  opacity: 0;
  transform: translateY(100px);
}

</style>





