<template>
  <div
    ref="containerRef"
    class="relative flex items-start justify-center p-2 h-fit md:px-20"
  >
  
    <div
      ref="cardWrapperRef"
      class="relative w-full py-6 md:py-10 transform-container"
    >
      <ContainerScrollTitle>
        <slot name="title"></slot>
      </ContainerScrollTitle>
      <div class="transform-wrapper">
        <ContainerScrollCard 
          :beam-width="48" 
          :border-thickness="5" 
          :aspect-ratio="'16/9'"
          class="screenshot-container rounded-[35px]" 
          ref="cardRef"
          :style="containerStyle"
        >
          <slot name="card" />
        </ContainerScrollCard>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useScroll, useWindowSize, useTransition, TransitionPresets } from '@vueuse/core'

const containerRef = ref(null)
const cardWrapperRef = ref(null)
const cardRef = ref(null)
const isReady = ref(false)

const props = defineProps<{
  scrollY: number
}>()

// Get window dimensions
const { height: windowHeight } = useWindowSize()

// Use smooth transitions for scroll progress with proper easing
const smoothProgress = useTransition(computed(() => {
  if (!isReady.value) return 1; // Start fully visible
  return Math.min(1, props.scrollY / 300);
}), {
  duration: 50,
  transition: TransitionPresets.easeOutQuad,
})

onMounted(() => {
  // Short delay to ensure proper initial positioning
  setTimeout(() => {
    isReady.value = true;
  }, 100);
})

// Composable for transform calculations
const useTransformStyle = (progress: Ref<number>) => {
  return computed(() => ({
    transform: [
      'perspective(2000px)',
      `rotateX(${50 * Math.pow(1 - progress.value, 4)}deg)`,
      `translateZ(${-windowHeight.value * .35 * (1 - progress.value)}px)`,
      `scale(${1 + (0.3 * (1 - progress.value))})`,
      `translateY(${200 * (1 - progress.value)}px)`
    ].join(' '),
    transformOrigin: '50% 0%',
    transformStyle: 'preserve-3d',
    backfaceVisibility: 'hidden',
    WebkitFontSmoothing: 'antialiased'
  }))
}

// Container style with composition
const containerStyle = computed(() => {
  const transformStyle = useTransformStyle(smoothProgress)
  
  // Create interpolated shadow layers based on xxxl style
  const progress = 1 - smoothProgress.value
  const shadows = [
    `1.9px 6.3px 2.5px rgba(0, 0, 0, ${0.06 * progress})`,
    `4.3px 14.3px 5.7px rgba(0, 0, 0, ${0.087 * progress})`, 
    `7.6px 24.9px 9.9px rgba(0, 0, 0, ${0.107 * progress})`,
    `12px 39.6px 15.8px rgba(0, 0, 0, ${0.125 * progress})`,
    `18.5px 61.1px 24.3px rgba(0, 0, 0, ${0.143 * progress})`,
    `28.9px 95.3px 38px rgba(0, 0, 0, ${0.163 * progress})`,
    `48px 158.2px 63.1px rgba(0, 0, 0, ${0.19 * progress})`,
    `96px 316px 126px rgba(0, 0, 0, ${0.25 * progress})`
  ]
  
  return {
    ...transformStyle.value,
    boxShadow: shadows.join(','),
    transition: 'transform 0.05s ease-out'
  }
})
</script>

<style scoped>
.transform-container,
.transform-wrapper {
  transform-style: preserve-3d;
}

.transform-wrapper {
  transform-origin: 50% 0%;
}

.screenshot-container {
  will-change: transform, box-shadow;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
</style>