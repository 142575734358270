<template>
  <canvas
    ref="starsCanvas"
    :class="cn('w-full h-full', $props.class)"
  ></canvas>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { cn } from "~/lib/utils";

function getColorFromClass(colorClass: string): string {
  // If it's already a hex color, return it
  if (colorClass.startsWith('#')) {
    return colorClass;
  }

  // Create a temporary div to get computed styles
  const div = document.createElement('div');
  div.className = colorClass;
  document.body.appendChild(div);
  const color = window.getComputedStyle(div).getPropertyValue('background-color');
  document.body.removeChild(div);
  return color;
}

interface Star {
  x: number;
  y: number;
  z: number;
  speed: number;
  opacity: number;
  age: number;
  fadeDistance: number;
}

const props = withDefaults(
  defineProps<{
    color?: string;
    count?: number;
    density?: number;
    class?: string;
    speed?: number;
    trailLength?: number;
    thickness?: number;
    opacity?: number;
  }>(),
  {
    color: "bg-primary",
    count: 200,
    density: 0,
    speed: 1,
    trailLength: 15,
    thickness: 1,
    opacity: 1,
  },
);

const starsCanvas = ref<HTMLCanvasElement | null>(null);
let perspective: number = 0;
let stars: Star[] = [];
let ctx: CanvasRenderingContext2D | null = null;

onMounted(() => {
  const canvas = starsCanvas.value;
  if (!canvas) return;

  window.addEventListener("resize", resizeCanvas);
  resizeCanvas(); // Call it initially to set correct size

  perspective = canvas.width / 2;
  stars = [];

  // Initialize stars with speed based on prop
  for (let i = 0; i < props.count; i++) {
    stars.push({
      x: (Math.random() - 0.5) * 2 * canvas.width,
      y: (Math.random() - 0.5) * 2 * canvas.height,
      z: Math.random() * canvas.width,
      speed: (Math.random() * 5 + 2) * props.speed, // Multiply base speed by prop
      opacity: 1,
      age: 0,
      fadeDistance: 0.1 + Math.random() * 0.2,
    });
  }

  animate(); // Start animation
});

function hexToRgb() {
  const color = getColorFromClass(props.color);
  
  // If it's an RGB/RGBA color, parse it
  if (color.startsWith('rgb')) {
    const values = color.match(/\d+/g);
    if (values && values.length >= 3) {
      return {
        r: parseInt(values[0]),
        g: parseInt(values[1]),
        b: parseInt(values[2])
      };
    }
  }

  // Otherwise assume it's a hex color
  let hex = color.replace(/^#/, "");

  // If the hex code is 3 characters, expand it to 6 characters
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Parse the r, g, b values from the hex string
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255; // Extract the red component
  const g = (bigint >> 8) & 255; // Extract the green component
  const b = bigint & 255; // Extract the blue component

  return {
    r,
    g,
    b,
  };
}

// Function to draw a star with a sharp line and blurred trail
function drawStar(star: Star) {
  const canvas = starsCanvas.value;
  if (!canvas) return;

  ctx = canvas.getContext("2d");
  if (!ctx) return;

  const scale = perspective / (perspective + star.z);
  const x2d = canvas.width / 2 + star.x * scale;
  const y2d = canvas.height / 2 + star.y * scale;
  const size = Math.max(scale * 3, 1);

  // Calculate trail length based on age
  const growDuration = 20;
  const trailLength = Math.min(star.age / growDuration, 1) * props.trailLength;

  const prevScale = perspective / (perspective + star.z + star.speed * trailLength);
  const xPrev = canvas.width / 2 + star.x * prevScale;
  const yPrev = canvas.height / 2 + star.y * prevScale;

  const rgb = hexToRgb();

  // Base opacity from props
  let alpha = props.opacity;

  // Only apply edge fading when very close to edges
  const edgeBuffer = canvas.width * star.fadeDistance;
  if (x2d < edgeBuffer || x2d > canvas.width - edgeBuffer || 
      y2d < edgeBuffer || y2d > canvas.height - edgeBuffer) {
    const edgeFadeX = Math.min(
      1,
      Math.min(
        (canvas.width - x2d) / edgeBuffer,
        x2d / edgeBuffer
      )
    );
    const edgeFadeY = Math.min(
      1,
      Math.min(
        (canvas.height - y2d) / edgeBuffer,
        y2d / edgeBuffer
      )
    );
    alpha *= Math.min(edgeFadeX, edgeFadeY);
  }

  // Sharp trail
  ctx.strokeStyle = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
  ctx.lineWidth = size * props.thickness;
  ctx.beginPath();
  ctx.moveTo(x2d, y2d);
  ctx.lineTo(xPrev, yPrev);
  ctx.stroke();

  // Star point
  ctx.fillStyle = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
  ctx.beginPath();
  ctx.arc(x2d, y2d, size * 0.3 * props.thickness, 0, Math.PI * 2);
  ctx.fill();
}

// Function to animate the stars
function animate() {
  const canvas = starsCanvas.value;
  if (!canvas) return;

  ctx = canvas.getContext("2d");
  if (!ctx) return;

  ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas for each frame

  stars.forEach((star) => {
    drawStar(star);
    star.age++;
    star.z -= star.speed;

    // Only fade when very close to viewer
    const fadeStartZ = canvas.width * 0.05; // Reduced fade zone to 5%
    if (star.z < fadeStartZ) {
      star.opacity = Math.max(0, star.z / fadeStartZ);
    }

    // Reset star
    if (star.z <= 0 || star.opacity <= 0) {
      star.z = canvas.width;
      star.x = (Math.random() - 0.5) * 2 * canvas.width;
      star.y = (Math.random() - 0.5) * 2 * canvas.height;
      star.opacity = 1;
      star.age = 0;
      star.fadeDistance = 0.1 + Math.random() * 0.2;
    }
  });

  requestAnimationFrame(animate); // Continue animation
}

// Set canvas to full screen
function resizeCanvas() {
  const canvas = starsCanvas.value;
  if (!canvas) return;

  canvas.width = canvas.clientWidth;
  canvas.height = canvas.clientHeight;

  // Recalculate number of stars based on density if specified
  if (props.density > 0) {
    const area = canvas.width * canvas.height;
    const newCount = Math.floor((area / 10000) * props.density);
    if (stars.length !== newCount) {
      stars = Array(newCount).fill(null).map(() => ({
        x: (Math.random() - 0.5) * 2 * canvas.width,
        y: (Math.random() - 0.5) * 2 * canvas.height,
        z: Math.random() * canvas.width,
        speed: (Math.random() * 5 + 2) * props.speed,
        opacity: 1,
        age: 0,
        fadeDistance: 0.1 + Math.random() * 0.2,
      }));
    }
  }
}
</script>