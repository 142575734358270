<template>
  <figure
    :class="['relative w-64 cursor-pointer overflow-hidden rounded-xl p-4', $attrs.class]" 
  >
    <div class="flex flex-row items-center gap-2">
      <slot />
      <div v-if="!props.img" class="w-8 h-8 rounded-full bg-muted-foreground/20 flex items-center justify-center">
        <span class="text-xs text-muted-foreground/60">{{ initials }}</span>
      </div>
      <NuxtImg 
        v-else
        sizes="48px"
        :src="`${props.img}`"
        class="w-8 h-8 rounded-full object-cover"
        :alt="`${name}'s avatar`"
        @error="handleImageError"
      />

      <div class="flex flex-col">
        <span class="text-sm font-light text-accent-foreground/80 !font-nationalpark !font-bold">
          {{ name }}
        </span>
        <p class="text-xs font-light text-accent-foreground/50 !font-nationalpark font-semibold">{{ username }}</p>
      </div>
    </div>
    <blockquote class="mt-2 text-sm font-light text-primary/80 !font-nationalpark font-medium blur-sm">{{ body }}</blockquote>
  </figure>
</template>

<script lang="ts" setup>
const props = defineProps({
  img: { type: String, required: true },
  name: { type: String, required: true },
  username: { type: String, required: true },
  body: { type: String, required: true },
});

const initials = computed(() => {
  return props.name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
});

const handleImageError = (e: Event) => {
  const target = e.target as HTMLImageElement;
  target.style.display = 'none';
  target.insertAdjacentHTML(
    'afterend',
    `<div class="w-8 h-8 rounded-full bg-muted-foreground/20 flex items-center justify-center">
      <span class="text-xs text-muted-foreground/60">${initials.value}</span>
    </div>`
  );
};
</script>