<script setup lang="ts">
interface Item {
  id: number;
  name: string;
  designation: string;
  image: string;
}

defineProps<{
  items: Item[];
}>();

const hoveredIndex = ref<number | null>(null);
const mouseX = ref<number>(0);

// Calculate rotation and translation based on mouse position
const rotation = computed<number>(() => {
  const x = mouseX.value;
  return (x / 100) * 50;
});

const translation = computed<number>(() => {
  const x = mouseX.value;
  return (x / 100) * 50;
});

// Handle initial mouse position and hover
function handleMouseEnter(event: MouseEvent, itemId: number) {
  hoveredIndex.value = itemId;
  // Calculate initial position immediately
  const rect = (event.target as HTMLElement)?.getBoundingClientRect();
  const halfWidth = rect.width / 2;
  mouseX.value = event.clientX - rect.left - halfWidth;
}

// Handle mouse movement
function handleMouseMove(event: MouseEvent) {
  const rect = (event.target as HTMLElement)?.getBoundingClientRect();
  const halfWidth = rect.width / 2;
  mouseX.value = event.clientX - rect.left - halfWidth;
}
</script>

<template>
  <div
    v-for="item in items"
    :key="item.id"
    class="group relative -mr-4"
    @mouseenter="(e) => handleMouseEnter(e, item.id)"
    @mouseleave="hoveredIndex = null"
    @mousemove="handleMouseMove"
  >
    <!-- Tooltip -->
    <div
      v-if="hoveredIndex === item.id"
      v-motion
      :initial="{
        opacity: 0,
        y: 20,
        scale: 0.6,
      }"
      :enter="{
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
          type: 'spring',
          stiffness: 260,
          damping: 10,
        },
      }"
      :leave="{
        opacity: 0,
        y: 20,
        scale: 0.6,
      }"
      :style="{
        translate: `${translation}px`,
        rotate: `${rotation}deg`,
      }"
      class="absolute -left-1/2 -top-[58px] z-50 flex translate-x-1/2 flex-col items-center justify-center whitespace-nowrap rounded-md bg-background px-4 py-2 text-xs shadow-xl"
    >
      
      <div class="relative z-30 text-base font-bold text-primary">
        {{ item.name }}
      </div>
      <div class="text-xs text-primary">{{ item.designation }}</div>
    </div>

    <!-- Avatar Image -->
    <NuxtImg
      :src="item.image"
      :alt="item.name"
      sizes="48px"
      class="relative !m-0 size-14 rounded-full border-2 border-white object-cover object-top !p-0 transition duration-500 group-hover:z-30 group-hover:scale-105"
    />
  </div>
</template>