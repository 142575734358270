<script setup lang="ts">
import { defineComponent, h } from 'vue'
const colorMode = useColorMode()

interface Props {
  imageSrc: string
  text?: string
  imagePosition?: 'left' | 'right'
  html?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  imagePosition: 'left',
  html: false,
  text: undefined
})

const imageInitial = computed(() => ({
  x: props.imagePosition === 'left' ? -100 : 100,
  opacity: 0
}))

const textInitial = computed(() => ({
  x: props.imagePosition === 'left' ? 100 : -100,
  opacity: 0
}))

const TextContent = defineComponent({
  props: {
    content: String,
    html: Boolean
  },
  setup(props) {
    if (props.html) {
      return () => h('div', { innerHTML: props.content })
    }
    return () => h('span', props.content)
  }
})
</script>

<template>
  <div class="w-full h-fit py-10 flex items-center justify-center flex-col my-[0vh] font-[600] relative">
    
    <!-- <AnimatedBlobs v-if="colorMode.value !== 'dark'" class="hidden sm:block !absolute inset-0" /> -->
    
    <div 
      class="w-fit flex items-center justify-center relative z-10"
      :class="imagePosition === 'right' ? 'flex-row-reverse' : 'flex-row'"
    >
    
      <NuxtImg
        v-motion
        :initial="imageInitial"
        :visible="{
          x: 0,
          opacity: 1,
          transition: {
            ease: [0.12, 0.991, 0.0, 0.999],
            duration: 1500,
            // delay: 250
          }
        }"
        :src="imageSrc"
        class="w-full min-w-[40vw] max-h-[40vh] min-h-[40vh] md:min-h-auto object-cover md:object-contain"
        sizes="100vw sm:300px md:800px"
        
        :class="{'object-left': imagePosition === 'right', 'object-right': imagePosition === 'left', 'rounded-r-2xl': imagePosition === 'left', 'rounded-l-2xl': imagePosition === 'right'}"
      />
      <p
        v-motion
        :initial="textInitial"
        :visible="{
          x: 0,
          opacity: 1,
          transition: {
            ease: [0.12, 0.991, 0.0, 0.999],
            duration: 2000,
            // delay: 450
          }
        }"
        class="text-2xl md:text-4xl font-nationalpark text-designer dark:text-[#fae1f6] tracking-tight px-[10vw] min-w-[50vw] flex items-center gap-2"
      >
        <slot name="text">
          {{ text }}
        </slot>
      </p>
      
    </div>
  </div>
</template> 