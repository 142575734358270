<template>
  <div class="relative flex h-full w-full items-center justify-center pointer-events-none" :class="$attrs.class">
    <div class="relative h-full w-full">
      <div class="relative mx-auto flex max-w-xl flex-col justify-start">
        <div
          v-for="(step, index) in decorativeSteps"
          :key="index"
        >
          <div
            class="mb-4 flex items-start gap-3 mt-4 text-left transition-all duration-300 ease-in-out"
            :style="{
              opacity:
                index === currentState
                  ? 1
                  : Math.max(1 - Math.abs(index - currentState) * 0.2, 0),
              transform: `translateY(${-(currentState * 80)}px)`,
            }"
          >
            <ClientOnly>
              <Icon
                v-if="index < currentState"
                name="heroicons:check-circle-solid"
                class="text-designer dark:text-primary min-w-5 min-h-5 max-h-5 max-w-5 relative"
              />
              <Icon 
                v-else-if="index === currentState" 
                name="line-md:loading-loop" 
                class="text-designer dark:text-primary min-w-5 min-h-5 max-h-5 max-w-5 relative"
              />
              <Icon
                v-else
                name="material-symbols:circle-outline"
                class="text-designer dark:text-primary min-w-5 min-h-5 max-h-5 max-w-5 opacity-0 relative"
              />
            </ClientOnly>
            <div class="flex flex-col -top-[2px] relative font-nationalpark font-[400]">
              <span
                :class="[
                  'text-sm sm:text-lg leading-[1.3em] text-designer/80 dark:text-primary/80 font-[300]',
                  index > currentState && 'opacity-50',
                ]"
                v-html="step.text"
              ></span>
              <Transition
                enter-active-class="transition-all duration-300"
                enter-from-class="opacity-0 -translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
              >
                <span
                  v-if="step.afterText && index < currentState"
                  class="mt-0 text-xs text-designer dark:text-primary tracking-normal"
                >{{ step.afterText }}</span>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

const decorativeSteps = [
  {
    text: "Import schedule (pdf, xls, scanned image)",
    afterText: "20 seconds",
  },
  {
    text: "Check pricing and availability",
    afterText: "5 seconds",
  },
  {
    text: "Check name errors and measure planting species diversity",
    afterText: "5 seconds",
  },
  {
    text: "Predict likely substitutes based on market availability",
    afterText: "5 seconds",
  },
  {
    text: "Identify suitable varieties with supplier catalogues",
    afterText: "5 seconds",
  },
  {
    text: "Generate tree establishment and maintenance report",
    afterText: "5 seconds",
  },
  
  {
    text: "Map species ocurrences near you",
    afterText: "5 seconds",
  },
  {
    text: "Export schedule data to CAD software",
    afterText: "2 seconds",
  }  
];

const currentState = ref(0);
const STEP_DURATION = 3000;
let animationTimer: NodeJS.Timeout;

function animate() {
  if (currentState.value < decorativeSteps.length - 1) {
    currentState.value++;
  } else {
    currentState.value = 0;
  }
  
  animationTimer = setTimeout(animate, STEP_DURATION);
}

onMounted(() => {
  animate();
});

onUnmounted(() => {
  clearTimeout(animationTimer);
});
</script>